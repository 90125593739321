import { FEES, FEES_SHOW } from "../endpoints";
import api from "../api";

export default {
    index(query) {
        let url = new URL(FEES);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    store(data = null) {
        if (data === null) return;

        return api.post(FEES, data);
    },

    destroy(fee = null) {
        if (fee === null) return;

        const url = FEES_SHOW.replace(":fee", fee);

        return api.delete(url);
    },

    // show(fee = null) {
    //     if (fee === null) return;

    //     const url = FEES_SHOW.replace(":fee", fee);

    //     return api.get(url);
    // },

    // update(fee, data) {
    //     if (fee === null) return;
    //     const url = FEES_SHOW.replace(":fee", fee);
    //     return api.put(url, data);
    // }
}