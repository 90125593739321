<template>
  <v-select
    :options="options"
    :selectName="selectName"
    :selectId="selectId"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    required
  ></v-select>
</template>

<script>
import feeApplicabilitiesApi from "@/api/resources/feeApplicabilitiesApi";
import VSelect from "@/components/ui/VSelect.vue";

export default {
  props: {
    selectName: String,
    selectId: String,
    modelValue: [String, Number],
  },
  emits: ["update:modelValue"],
  components: { VSelect },

  data() {
    return {
      options: [],
    };
  },

  methods: {
    getOptions() {
      feeApplicabilitiesApi.index().then((response) => {
        this.options = response.data.map((status) => {
          return { text: status.name, value: status.id };
        });
      });
    },
  },

  created() {
    this.getOptions();
  },
};
</script>

<style></style>
