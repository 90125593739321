<template>
  <app-layout>
    <template #header> Adauga comision </template>

    <page-section>
      <fees-create-form
        class="mb-6"
        :vendor-id="id"
        @fee:created="redirect()"
      />
    </page-section>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import FeesCreateForm from "../components/FeesCreateForm.vue";
import PageSection from '../components/ui/PageSection.vue';

export default {
  components: {
    AppLayout,
    FeesCreateForm,
    PageSection,
  },

  props: ["id"],

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    redirect() {
      this.$router.push({
        name: "fees.index",
      });
    },
  },
};
</script>

<style></style>
